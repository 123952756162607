/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// TODO: change for production
const API_BASE_URL = '/';

const field_required_error = 'This field is required';

const app = new Vue({
    el: '#app',
    data: {
        view: 'login',
        views: ['login', 'link', 'loading', 'success'],

        alert: null,

        login: {
            loading: false,
            error: '',
            username: {
                field: '',
                error: ''
            },
            password: {
                field: '',
                error: ''
            },
        },

        success: {
            message: ''
        },

        link: {
            html: '',
        }
    },
    mounted() {
        window.addEventListener("message", this.on_message, false);
    },
    methods: {
        on_message: function (message) {
            let data = message.data;

            this.log_event(data);

            if (!data || !data.from || !data.from == 'link' || !data.metadata) return;

            switch (data.type) {
                case 'success':

                    this.ba_selected(data.metadata);

                    break;
                case 'error':
                    if (data.error) this.script_error(data.error);
                    else this.reset();
                    break;
            }
        },
        log_event: function(event) {
            let data = {};

            switch (event.type) {
                case 'success':
                    data = event.metadata;
                    break;
                case 'event':
                    data = {...event.metadata, event: event.event};
                    break;
                case 'error':
                    data = {...event.metadata}

                    if (event.error != null) data = Object.assign({}, data, event.error);

                    break;
                default:
                    return;
            }

            axios.post('/session', data, { headers: { Accept: 'application/json' } })
            .then(response => {})
            .catch(error => {});
        },
        login_submitted: function () {
            let self = this;
            let form = this.login;

            let username = form.username.field;
            let password = form.password.field;

            if (!username || !username.length) {
                return form.username.error = field_required_error;
            } else form.username.error = '';

            if (!password || !password.length) {
                return form.password.error = field_required_error;
            } else form.password.error = '';

            this.loading('login');
            this.alert = null;

            axios.post('/login', { 'email': username, password }, { headers: { Accept: 'application/json' } })
                .then(response => {
                    form.username.field = '';
                    form.password.field = '';

                    this.view = this.views[1];

                    let head = document.getElementsByTagName('head')[0];
                    let script = document.createElement('script');

                    script.type = 'text/javascript';
                    script.src = '/link.js';
                    script.onload = () => {
                        this.script_loaded();
                    }
                    script.onerror = (error) => {
                        this.script_error(error);
                    }

                    try {
                        head.appendChild(script);
                    } catch (error) {
                        console.error(error);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status) {
                        if (error.response.status == 422) {
                            if (error.response.data && error.response.data.errors) {
                                let errors = error.response.data.errors;
                                if (errors.email && errors.email.length) {
                                    form.username.error = errors.email[0];
                                }

                                if (errors.password && errors.password.length) {
                                    form.password.error = errors.password[0];
                                }
                            }
                            return this.toastr('One or more fields have errors.', 'danger')
                        } else if ([419, 401].indexOf(error.response.status) > -1) {
                            if (error.response.data && error.response.data.message)
                                return form.username.error = error.response.data.message;
                            else
                                return this.toastr('An unexpected Authentication or Session error has occurred.', 'danger');
                        } else if ([403].indexOf(error.response.status) > -1) {
                            return this.toastr('You don\'t seem to have the right permissions to make this request.', 'danger');
                        }

                        this.toastr(`An unexpected error occurred during the request${error.response.status ? ' (code: ' + error.response.status + ')' : ''}.`, 'danger');
                    } else {
                        this.toastr(`An unexpected error occurred during the request.`, 'danger');
                    }
                })
                .finally(() => {
                    this.unloading('login');
                });
        },
        ba_selected(metadata) {
            this.view = this.views[2];
            this.alert = null;

            if (metadata.institution && metadata.institution.institution_id && metadata.institution.institution_id === 'ins_56') {
                return this.toastr(
                    'Unfortunately Cherry does not support adding Chase Bank Accounts through Plaid, please contact support@cherrynet.com to have your Chase Bank Account connected.'
                , 'danger');
            }

            axios.post('/bank-account', {
                link_session_id: metadata.link_session_id,
                public_token: metadata.public_token,
                account_id: metadata.account_id
            }, { headers: { Accept: 'application/json' } })
            .then(response => {
                this.view = this.views[3];
                this.success.message = response.data.message;

                if (response.data.success && response.data.meta) {
                    parent.postMessage(response.data, '*')
                }
            })
            .catch(error => {
                this.view = this.views[0];

                if (error.response && error.response.status) {
                    if (error.response.status == 422) {
                        if (error.response.data && error.response.data.errors) {
                            let errors = error.response.data.errors;

                            if (errors.message && errors.message.length) return this.toastr(errors.message[0], 'danger');
                        }
                        return this.toastr('One or more fields have errors.', 'danger')
                    } else if ([419, 401].indexOf(error.response.status) > -1) {
                        if (error.response.data && error.response.data.message)
                            return this.toastr(error.response.data.message, 'danger');
                        else
                            return this.toastr('An unexpected Authentication or Session error has occurred.', 'danger');
                    } else if ([403].indexOf(error.response.status) > -1) {
                        return this.toastr('You don\'t seem to have the right permissions to make this request.', 'danger');
                    }

                    this.toastr(`An unexpected error occurred during the request${error.response.status ? ' (code: ' + error.response.status + ')' : ''}.`, 'danger');
                } else {
                    this.toastr(`An unexpected error occurred during the request.`, 'danger');
                }
            })
            .finally(() => {
            });
        },
        script_loaded() {
            handler.open();
        },
        script_error(error) {
            this.toastr('There was an error with the Bank Account Connector module.', 'danger');
            this.reset();
        },
        isloading(form) {
            return this[form].loading;
        },
        loading(form) {
            this[form].loading = true;
        },
        unloading(form) {
            this[form].loading = false;
        },
        toastr(message, type) {
            this.alert = {
                message, type
            };
        },
        reset() {
            this.view = this.views[0];

            this.login.username.field = '';
            this.login.username.error = '';

            this.login.password.field = '';
            this.login.password.error = '';

        }
    }
});
